import { render, staticRenderFns } from "./badges.vue?vue&type=template&id=34fb7cb1&scoped=true&lang=pug&"
import script from "./badges.vue?vue&type=script&lang=coffee&"
export * from "./badges.vue?vue&type=script&lang=coffee&"
import style0 from "./badges.vue?vue&type=style&index=0&id=34fb7cb1&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34fb7cb1",
  null
  
)

export default component.exports