import { render, staticRenderFns } from "./medium-card.vue?vue&type=template&id=255a840c&scoped=true&lang=pug&"
import script from "./medium-card.vue?vue&type=script&lang=coffee&"
export * from "./medium-card.vue?vue&type=script&lang=coffee&"
import style0 from "./medium-card.vue?vue&type=style&index=0&id=255a840c&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "255a840c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CraftVisual: require('/opt/build/repo/node_modules/@bkwld/cloak/components/craft-visual.coffee').default,Badges: require('/opt/build/repo/nuxt-app/components/globals/badges.vue').default,Btn: require('/opt/build/repo/nuxt-app/components/globals/btn/btn.vue').default,SmartLink: require('/opt/build/repo/nuxt-app/components/globals/smart-link.coffee').default})
