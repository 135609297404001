













import breakpoints from 'library/assets/vars/breakpoints'
export default

	props: block: Object

	data: ->
		config:
			slidesPerPage: 4
			showDots: true
			gutter: 'var(--gutter)'
			peekLeft: 'var(--gutter)'
			peekRight: 'var(--peek-right)'
			responsive: [
				{
					minWidth: parseInt breakpoints.desktop
					feather: 'var(--gutter)'
				}
				{
					maxWidth: parseInt breakpoints.tablet
					slidesPerPage: 3
				}
				{
					maxWidth: parseInt breakpoints.mobile
					slidesPerPage: 1
				}
			]

	computed:
		cards: -> @block.cards || []

