



















export default
	props:

		# Array of {title, style, color} objects
		#   'style' is inline style {background, color}
		#   'color' is brand color name (ie. 'white') that sets background and color automatically
		#   Please use either 'style' or 'color' prop, but not both.
		badges:
			type: Array
			default: -> []

