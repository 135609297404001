import { render, staticRenderFns } from "./card-carousel.vue?vue&type=template&id=c1808f62&scoped=true&lang=pug&"
import script from "./card-carousel.vue?vue&type=script&lang=coffee&"
export * from "./card-carousel.vue?vue&type=script&lang=coffee&"
import style0 from "./card-carousel.vue?vue&type=style&index=0&id=c1808f62&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c1808f62",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardsMediumCard: require('/opt/build/repo/nuxt-app/components/globals/cards/medium-card.vue').default,SsrCarousel: require('/opt/build/repo/node_modules/vue-ssr-carousel/index.js').default})
