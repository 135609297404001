












































export default
	name: 'MediumCard'
	props:

		card: Object

		titleStyle:
			type: String
			default: 'h4'
			validator: (val) -> val in ['h4', 'h4-alt', 'h4-medium']

	computed:

		classes: -> [
			"title-#{@titleStyle}"
			if @card.new then 'new'
		]

		badges: -> if @card.new then [{title:'New', color:'white'}]

